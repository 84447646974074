$basic-color: #282c34;
$first-color: #ffffff;
$second-color: #b8390e;
$third-color: #d9ab30;
$fourth-color: #00ebeb;

$text-first-color: #fff;
$text-second-color: #000;
$text-third-color: #aaa;

$s-font-size: 14px;
$m-font-size: 18px;
$l-font-size: 20px;
$x-font-size: 24px;
